body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div.contentBody {
  margin: 8px;
}

label.inputBlock {
  display: block;
  margin-top: 1em;
}

.inputBlock textarea {
  width: 600px;
  height: 600px;
  display: inline-block;
  margin-left: 8px;
  vertical-align: top;
}

label div.dataTemplate {
  display: inline-block;
  font-family: monospace;
  white-space: pre;
  vertical-align: top;
  background: aliceblue;
  max-height: 600px;
  border: solid 1px #99c;
  padding: 8px;
  margin-left: 8px;
  overflow: scroll;
}

.filterControl {
  margin: 8px;
}

.chartBox {
  width: 400px;
  padding: 20px;
  border-radius: 20px;
  display: inline-block;
}

canvas {
  display: inline-block !important;
}

#usageHistoryBlock {
  width: 100%;
}

#usageProfileBlock {
  height:500px;
}

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
}

.spinner > div {
  background-color: #333;
  height: 24px;
  width: 5px;
  margin: 0 3px;
  animation: spinner 1s infinite ease-in-out;
}

.spinner .rect2 {
  animation-delay: -0.9s;
}

.spinner .rect3 {
  animation-delay: -0.8s;
}

.spinner .rect4 {
  animation-delay: -0.7s;
}

.spinner .rect5 {
  animation-delay: -0.6s;
}

@keyframes spinner {
  0%, 40%, 100% { 
      transform: scaleY(0.4);
  }  20% { 
      transform: scaleY(1.0);
  }
}

tr.DEBIT td.amount {
  color: red;
}

tr.CREDIT td.amount {
  color: green;
}

div.flash-banner {
  position: relative;
  border: solid 1px grey;
  background-color: silver;
  font-weight: bold;
  padding: 1em;
}

div.flash-banner.success {
  border: solid 1px green;
  background-color: palegreen;

}

div.flash-banner.fail {
  border: solid 1px red;
  background-color: palevioletred;

}

.close-toast {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  /* Additional styling if needed */
}